import 'bootstrap-select'
const $ = jQuery

const defaults = $.fn.selectpicker.Constructor.DEFAULTS

defaults.iconBase = 'fal'
// defaults.mobile = true;
defaults.tickIcon = 'fa-check'
defaults.width = '100%'
defaults.style = ''

jQuery(($) => {
  $('.wpcf7-select, .selectpicker').selectpicker()
})

window.addEventListener('keypress', (e) => {
  if (
    !(e.target instanceof HTMLElement) ||
    (e.key !== 'Enter' && e.key !== ' ')
  ) {
    return
  }
  const label = e.target?.closest<HTMLLabelElement>('label')
  const input = document.getElementById(label?.htmlFor ?? '')
  if (!(input instanceof HTMLInputElement) || input.type !== 'file') {
    return
  }
  input.dispatchEvent(new MouseEvent('click'))
  e.preventDefault()
  e.stopPropagation()
})

function updateOptionalFormGroups() {
  for (const group of Array.from(
    document.querySelectorAll<HTMLElement>('[data-form-group-optional-if]')
  )) {
    const form = group.closest('form')
    if (!form) {
      continue
    }
    const input = form.querySelector<HTMLInputElement>(
      `[name="${group.dataset.formGroupOptionalIf}"]`
    )
    let show = true
    if (input instanceof HTMLInputElement) {
      if (input.type === 'checkbox' || input.type === 'radio') {
        show = input.checked
      } else {
        show = !!input.value
      }
    }
    if (!show) {
      group.hidden = true
      for (const input of Array.from(
        group.querySelectorAll<HTMLInputElement>('input, select, textarea')
      )) {
        input.dataset.required =
          input.dataset.required ?? input.required ? 'true' : 'false'
        input.required = false
      }
    } else {
      group.hidden = false
      for (const input of Array.from(
        group.querySelectorAll<HTMLInputElement>('input, select, textarea')
      )) {
        input.required = input.dataset.required === 'true'
      }
    }
  }
}

updateOptionalFormGroups()
document.addEventListener('change', updateOptionalFormGroups)
